import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AlertDialog } from '../components/alertDialog';
import { LoadingButton } from '../components/loadingButton';
import { useApi, useContext } from '../context';
import { FormField } from '../services/types/formField.type';
import utils from '../services/utils.service';

const useStlyes = makeStyles((theme) => ({
  option: {
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}))

export const FindResult = () => {
  const theme = useTheme();
  const { t } = useTranslation('check');
  const history = useHistory();

  const [showError, setShowError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const api = useApi();

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      reference: {
        value: '',
        errorState: false,
        helperText: ''
      },
    }
  );

  const handleFormChange = (fieldName: string, value: string) => {
    updateForm({ field: fieldName, data: { value, errorState: false, helperText: '' } });
  }

  const validate = (): boolean => {
    let hasError = false;
    if (form.reference.value.length === 0) {
      updateForm({ field: 'reference', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    return !hasError;
  }

  const submit = () => {
    if (!validate()) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.getResult(form.reference.value);
      history.push('/result/' + form.reference.value);
    }, (e) => {
      setLoading(false);
      if (e) {
        setShowError(true);
      }
    });
  }

  return (
    <Container>
      <AlertDialog
        open={showError}
        onClose={() => {
          setShowError(false);
        }}
        title={t('CODE_NOT_FOUND_TITLE')}
        message={t('CODE_NOT_FOUND_MESSAGE')}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('FIND_RESULT_BY_CODE_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align='justify'>
            {t('FIND_RESULT_BY_CODE_HELPTEXT')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ paddingTop: theme.spacing(2) }}>
            <Grid item xs={12} md={12}>
              <TextField
                label={t('CODE')}
                value={form.reference.value}
                error={form.reference.errorState}
                helperText={form.reference.helperText ? t(form.reference.helperText) : ''}
                onChange={(e) => {
                  handleFormChange('reference', e.target.value);
                }}
                variant='outlined'
                fullWidth={true}
                inputProps={{
                  autoComplete: 'chrome-off',
                }}
              />
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end'>
              <LoadingButton
                variant='contained'
                loading={loading}
                disabled={loading}
                onClick={() => {
                  submit();
                }}
                color='primary'
                size='large'>
                {t('CONTINUE')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>

  )

}