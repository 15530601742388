import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { FormField } from '../services/types/formField.type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../context';
import utils from '../services/utils.service';
import { Category } from '../services/types/category.type';
import { SubCategory } from '../services/types/subCategory.type';
import { HelpText } from '../components/helpText';

type DecoratedCategory = Category & {
  subCategories: SubCategory[];
}

export const AddDeviceModal = ({ show, onDeviceAdded, onClose }: {
  show: boolean
  onDeviceAdded: (device: { name: string, subcategory: SubCategory }) => void,
  onClose: () => void
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation('check');
  const api = useApi();

  const [selectedSubCategory, setSelectedSubCategory] = React.useState<SubCategory | null>(null);
  const [categories, setCategories] = React.useState<DecoratedCategory[]>([]);
  const [loading, setLoading] = React.useState(true);


  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      const cats = await api.getCategories();
      const categoriesWithSubCategories = [];
      for (const category of cats) {
        const subCategories = await api.getSubCategories(category.id);
        categoriesWithSubCategories.push({
          ...category,
          subCategories
        })
      }
      setCategories(categoriesWithSubCategories);
    }, (e) => {
      if (!e) {
        setLoading(false);
      }
    })
  }, []);

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      deviceName: {
        value: '',
        errorState: false,
        helperText: ''
      }
    }
  );

  const handleFormChange = (fieldName: string, value: string) => {
    updateForm({ field: fieldName, data: { value, errorState: false, helperText: '' } });
  }

  const validate = (): boolean => {
    let hasError = false;
    if (form.deviceName.value.length === 0) {
      updateForm({ field: 'deviceName', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    return !hasError;
  }

  const submit = () => {
    if (!validate() || !selectedSubCategory) {
      return;
    }
    onDeviceAdded({
      name: form.deviceName.value,
      subcategory: selectedSubCategory
    });
    onClose();
  }

  React.useEffect(()=>{
    updateForm({field: 'deviceName', data: {value: ''}});
    setSelectedSubCategory(null);
  },[show]);

  return (
    <Dialog
      fullWidth={true}
      open={show}
      onClose={onClose}
    >
      <DialogTitle disableTypography id='dialog-title'>
        <Typography variant='h4'>
          {t('ADD_DEVICE_MODAL_TITLE')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align='justify'>
              {t('ADD_DEVICE_MODAL_INTRO')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ marginTop: theme.spacing(2) }}
              label={t('NOMBRE_DISPOSITIVO')}
              value={form.deviceName.value}
              error={form.deviceName.errorState}
              helperText={form.deviceName.helperText ? t(form.deviceName.helperText) : ''}
              onChange={(e) => {
                handleFormChange('deviceName', e.target.value);
              }}
              variant='outlined'
              fullWidth={true}
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          {loading &&
            <Grid item container justifyContent='center' style={{ marginTop: theme.spacing(4) }}>
              <CircularProgress />
            </Grid>
          }
          <Grid item xs={12}>
            <Grid container spacing={2} style={{ paddingTop: theme.spacing(2) }}>
              {categories.map(c => {
                return (
                  <React.Fragment key={c.id}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1'>
                        {utils.getLocalizedValue(i18n, c.name)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" style={{ paddingLeft: theme.spacing(2) }}>
                        <FormGroup>
                          {c.subCategories.map(s => {
                            return (
                              <React.Fragment key={s.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color='primary'
                                      checked={selectedSubCategory?.id === s.id}
                                      onChange={() => {
                                        setSelectedSubCategory(s);
                                      }} />
                                  }
                                  label={
                                    <>
                                      <Typography component='span'>
                                        {utils.getLocalizedValue(i18n, s.name)}
                                      </Typography>
                                      <HelpText text={s.help_text} />
                                    </>
                                  }
                                />
                              </React.Fragment>
                            )
                          })}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size='large' disabled={selectedSubCategory === null} variant='contained' onClick={() => {
          submit();
        }} color='primary' autoFocus>
          {t('AÑADIR')}
        </Button>
      </DialogActions>
    </Dialog >

  )

}