import { Button, createStyles, Grid, Link, makeStyles, Menu, MenuItem, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import introVideoPicture from '../assets/img/intro-video.jpg';
import girlBackground from '../assets/img/girl-background.jpg';
import girlBackgroundMobile from '../assets/img/girl.jpg';
import logo from '../assets/img/logo-sicheck-white.svg'
import bullet from '../assets/img/bullet.svg';
import video from '../assets/sicheck-video-720.mp4';
import logoConselleria from '../assets/img/conselleria.png';
import { SicheckButton } from '../components/sicheckButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import sponsor1 from '../assets/img/sponsors/sponsor1.png';
import sponsor2 from '../assets/img/sponsors/sponsor2.png';
import sponsor3 from '../assets/img/sponsors/sponsor3.png';
import sponsor4 from '../assets/img/sponsors/sponsor4.png';
import sponsor5 from '../assets/img/sponsors/sponsor5.png';
import sponsor6 from '../assets/img/sponsors/sponsor6.png';
import sponsor7 from '../assets/img/sponsors/sponsor7.jpg';
import sponsor8 from '../assets/img/sponsors/sponsor8.jpg';
import sponsor9 from '../assets/img/sponsors/sponsor9.jpg';
import imgOne from '../assets/img/one.svg';
import imgTwo from '../assets/img/two.svg';
import imgThree from '../assets/img/three.svg';
import imgFour from '../assets/img/four.svg';
import imgFive from '../assets/img/five.svg';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: 'relative',
      backgroundColor: theme.palette.primary.main
    },
    logo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(4)
    },
    blackBar: {
      width: '100%',
      height: 80,
      backgroundColor: '#000'
    },
    logoContainer: {
      position: 'absolute',
      top: 15,
      width: '100%',
    },
    languageBox: {
      position: 'absolute',
      top: 10,
      right: 10
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
    },
    hero: {
      width: '100%',
      height: 540,
      backgroundImage: `url(${introVideoPicture})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    content: {
      position: 'absolute',
      top: 100,
      width: '100%',
    },
  }),
);


const LanguageButton = () => {
  const { t, i18n } = useTranslation('layout');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: 'ca' | 'es') => {
    i18n.changeLanguage(language);
    closeLanguageMenu();
  }

  const getCurrentLanguage = () => {
    if (i18n.language.includes('ca')) {
      return t('VALENCIANO');
    } else {
      return t('CASTELLANO');
    }
  }

  return (
    <>
      <Button aria-controls='language-menu' aria-haspopup='true' style={{ color: '#FFF' }} onClick={openLanguageMenu}>
        <LanguageIcon style={{ color: '#FFF' }} />
        {getCurrentLanguage()}
        <ExpandMoreIcon fontSize='small' style={{ color: '#FFF' }} />
      </Button>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageMenu}
      >
        <MenuItem onClick={() => setLanguage('es')}>{t('CASTELLANO')}</MenuItem>
        <MenuItem onClick={() => setLanguage('ca')}>{t('VALENCIANO')}</MenuItem>
      </Menu>
    </>
  );
};

export const Landing = () => {

  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('layout');


  return (
    <>
      <div className={classes.header}>
        <div className={classes.overlay}></div>
        {isXs &&
          <>
            <div className={classes.blackBar} />
            <div className={classes.hero} />
          </>
        }
        {!isXs &&
          <>
            <video
              style={{ width: '100%' }}
              autoPlay
              loop
              playsInline
              muted
              id="video-id"
            >
              <source src={video} type="video/mp4" />
            </video>
          </>
        }
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo}></img>
        </div>
        <div className={classes.languageBox}>
          <LanguageButton />
        </div>
        <div className={classes.content}>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 80 }}>
              <Typography variant='h3' style={{ color: 'white', fontWeight: 600 }} align='center'>
                {t('LANDING_TITLE')}
              </Typography>
            </Grid>
            <Grid container item justifyContent='center' style={{ marginTop: 20 }}>
              <Typography variant='h4' style={{ color: 'white', maxWidth: 600 }} align='center'>
                {t('LANDING_SUBTITLE')}
              </Typography>
            </Grid>
            <Grid container item justifyContent='center' style={{ marginTop: 50 }}>
              <SicheckButton text={t('ACCEDE_AHORA')} color='white' link={'/check'} />
            </Grid>
            {isXs &&
              <Grid container item justifyContent='center' alignItems='center' style={{ marginTop: 40 }}>
                <Link
                  style={{
                    color: '#FFF',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  href={`${video}`}
                  underline='none'
                  target='_blank'>
                  <Typography component='span'>
                    {t('VER_VIDEO')}
                  </Typography>
                  <PlayCircleOutlineIcon
                    fontSize='large'
                    style={{
                      color: '#FFF',
                      marginLeft: 5
                    }}
                  />
                </Link>
              </Grid>
            }
          </Grid>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} style={{
          backgroundColor: theme.palette.primary.main,
          paddingTop: theme.spacing(isXs ? 6 : 15),
          paddingBottom: theme.spacing(isXs ? 6 : 15),
          paddingLeft: theme.spacing(isXs ? 2 : 10),
          paddingRight: theme.spacing(isXs ? 2 : 10),
        }}>
          <Grid container spacing={4} justifyContent='center'>
            <Grid item xs={12} md={3}>
              <Typography variant={'h4'} align='center' style={{ color: theme.palette.primary.contrastText }}>
                {t('QUE_ES_LA_SEGURIDAD_INDUSTRIAL_TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography align='justify' style={{ color: theme.palette.primary.contrastText }}>
                {t('QUE_ES_LA_SEGURIDAD_INDUSTRIAL_CONTENT')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{
          backgroundImage: isXs ? `url(${girlBackgroundMobile})` : `url(${girlBackground})`,
          backgroundPosition: 'right',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          paddingTop: theme.spacing(isXs ? 6 : 15),
          paddingBottom: theme.spacing(isXs ? 6 : 15),
          paddingLeft: theme.spacing(isXs ? 2 : 10),
          paddingRight: theme.spacing(isXs ? 2 : 10),
        }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontWeight: 600, maxWidth: isXs ? '100%' : 550 }} gutterBottom>
                {t('PLAN_DE_ACCION_TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
              <Typography variant='h6' align={isXs ? 'center' : 'left'} style={{ fontStyle: 'italic', maxWidth: isXs ? '100%' : 550 }} gutterBottom>
                {t('PLAN_DE_ACCION_CONTENT')}
              </Typography>
            </Grid>
            <Grid item container alignItems='flex-start' xs={12}>
              {!isXs &&
                <img src={bullet} style={{ marginTop: 5, marginRight: 5 }} />
              }
              <Typography align={isXs ? 'center' : 'left'} style={{ maxWidth: isXs ? '100%' : 550 }} gutterBottom>
                {t('PLAN_DE_ACCION_CONTENT_1')}
              </Typography>
            </Grid>
            <Grid container alignItems='flex-start' item xs={12}>
              {!isXs &&
                <img src={bullet} style={{ marginTop: 5, marginRight: 5 }} />
              }
              <Typography align={isXs ? 'center' : 'left'} style={{ maxWidth: isXs ? '100%' : 550 }} gutterBottom>
                {t('PLAN_DE_ACCION_CONTENT_2')}
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent={isXs ? 'center' : 'flex-start'} style={{ marginTop: theme.spacing(6) }}>
              <SicheckButton link={'/check'} text={t('ACCEDE_AHORA')} color='primary' />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Grid container>
        <Grid item xs={12} style={{
          backgroundColor: '#ECECED',
          paddingTop: theme.spacing(isXs ? 6 : 15),
          paddingBottom: theme.spacing(isXs ? 6 : 15),
          paddingLeft: theme.spacing(isXs ? 2 : 10),
          paddingRight: theme.spacing(isXs ? 2 : 10),
        }}>
          <Carousel
            swipeable={true}
            draggable={true}
            arrows={false}
            showDots={true}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              }
            }}
            infinite={true}
            itemClass="carousel-item-padding-40-px"
            containerClass="carousel-container"
            autoPlay={true}
            autoPlaySpeed={8000}
            keyBoardControl={true}
          >
            <Grid container key={1} style={{ pointerEvents: 'none', userSelect: 'none' }}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontWeight: 600 }} gutterBottom>
                      {t('COMO_TENER_UN_ESTABLECIMIENTO_SEGURO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontStyle: 'italic', color: theme.palette.primary.main }} gutterBottom>
                      {t('TENER_BUEN_PROYECTO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: theme.spacing(4) }}>
                    <Typography align={isXs ? 'center' : 'left'} style={{ color: theme.palette.primary.main }} gutterBottom>
                      {t('BUEN_DISEÑO')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isXs &&
                <Grid item xs={12} md={6}>
                  <img src={imgOne} />
                </Grid>
              }
            </Grid>
            <Grid container key={2} style={{ pointerEvents: 'none', userSelect: 'none' }}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontWeight: 600 }} gutterBottom>
                      {t('COMO_TENER_UN_ESTABLECIMIENTO_SEGURO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontStyle: 'italic', color: theme.palette.primary.main }} gutterBottom>
                      {t('PUESTA_MARCHA_ADECUADA')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: theme.spacing(4) }}>
                    <Typography align={isXs ? 'center' : 'left'} style={{ color: theme.palette.primary.main }} gutterBottom>
                      {t('COMPROBACION_INSTALACIONES')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isXs &&
                <Grid item xs={12} md={6}>
                  <img src={imgTwo} />
                </Grid>
              }
            </Grid>
            <Grid container key={3} style={{ pointerEvents: 'none', userSelect: 'none' }}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontWeight: 600 }} gutterBottom>
                      {t('COMO_TENER_UN_ESTABLECIMIENTO_SEGURO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontStyle: 'italic', color: theme.palette.primary.main }} gutterBottom>
                      {t('LEGALIZAR_ESTABLECIMIENTO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: theme.spacing(4) }}>
                    <Typography align={isXs ? 'center' : 'left'} style={{ color: theme.palette.primary.main }} gutterBottom>
                      {t('PRESENTAR_DOCUMENTACION')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isXs &&
                <Grid item xs={12} md={6}>
                  <img src={imgThree} />
                </Grid>
              }
            </Grid>
            <Grid container key={4} style={{ pointerEvents: 'none', userSelect: 'none' }}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontWeight: 600 }} gutterBottom>
                      {t('COMO_TENER_UN_ESTABLECIMIENTO_SEGURO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontStyle: 'italic', color: theme.palette.primary.main }} gutterBottom>
                      {t('REALIZAR_MANTENIMIENTO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: theme.spacing(4) }}>
                    <Typography align={isXs ? 'center' : 'left'} style={{ color: theme.palette.primary.main }} gutterBottom>
                      {t('PREVENCION')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isXs &&
                <Grid item xs={12} md={6}>
                  <img src={imgFour} />
                </Grid>
              }
            </Grid>
            <Grid container key={5} style={{ pointerEvents: 'none', userSelect: 'none' }}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontWeight: 600 }} gutterBottom>
                      {t('COMO_TENER_UN_ESTABLECIMIENTO_SEGURO')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
                    <Typography variant='h5' align={isXs ? 'center' : 'left'} style={{ fontStyle: 'italic', color: theme.palette.primary.main }} gutterBottom>
                      {t('REALIZAR_INSPECCIONES')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: theme.spacing(4) }}>
                    <Typography align={isXs ? 'center' : 'left'} style={{ color: theme.palette.primary.main }} gutterBottom>
                      {t('REALIZAR_INSPECCIONES_OFICIALES')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isXs &&
                <Grid item xs={12} md={6}>
                  <img src={imgFive} />
                </Grid>
              }
            </Grid>
          </Carousel>

        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{
          backgroundColor: theme.palette.primary.main,
          paddingTop: theme.spacing(isXs ? 6 : 15),
          paddingBottom: theme.spacing(isXs ? 6 : 15),
        }}>
          <Grid container justifyContent='center'>
            <Grid item xs={12} md={4}>
              <Grid container justifyContent={'center'}>
                <img src={logoConselleria} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: isXs ? theme.spacing(6) : 0 }}>
              <Grid container justifyContent={'center'}>
                <img src={logo} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: '#FFF',
            paddingTop: theme.spacing(isXs ? 6 : 15),
            paddingBottom: theme.spacing(isXs ? 6 : 15),
          }}
        >
          <Carousel
            swipeable={true}
            draggable={true}
            arrows={false}
            showDots={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              }
            }}
            infinite={true}
            centerMode={isXs ? false : true}
            itemClass="carousel-item-padding-40-px"
            containerClass="carousel-container"
            autoPlay={true}
            autoPlaySpeed={1500}
            keyBoardControl={false}
          >
            <a href="https://aseival.es/"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor1} /></a>
            <a href="https://cogiticova.es"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor2} /></a>
            <a href="https://www.cev.es/"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor3} /></a>
            <a href="https://www.fempa.es/"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor4} /></a>
            <a href="https://cindi.gva.es/es/web/industria-e-i-d-i/seguretat-industrial"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor5} /></a>
            <a href="https://www.femeval.es/"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor6} /></a>
            <a href="https://www.ugt-pv.es/cms/"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor7} /></a>
            <a href="https://pv.ccoo.es"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor8} /></a>
            <a href="https://iicv.net/"><img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src={sponsor9} /></a>
          </Carousel>
        </Grid>
      </Grid>
    </>


  );

}