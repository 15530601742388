import { Config } from '../config';
import { Answer } from './types/answer.type';
import { Category } from './types/category.type';
import { DeviceWithAnswers } from './types/deviceWithAnswers.type';
import { Question } from './types/question.type';
import { Result } from './types/result.type';
import { ReultWithAnswers } from './types/resultWithAnswers.type';
import { SubCategory } from './types/subCategory.type';
import { UserData } from './types/userData.type';

export class Api {

  constructor(
    private readonly config: Config,
  ) { }

  async getCategories(): Promise<Category[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/categories'
    );
    if (!response.ok) {
      throw new Error('Could not get categories');
    }
    return (await response.json()).categories;
  }

  async getSubCategories(categoryId?: number): Promise<SubCategory[]> {
    let response;
    if (categoryId) {
      response = await fetch(
        `${this.config.API_BASE_URL}/categories/${categoryId}/sub_categories`
      );
    } else {
      response = await fetch(
        `${this.config.API_BASE_URL}/sub_categories`
      );
    }
    if (!response.ok) {
      throw new Error('Could not get sub categories');
    }
    return (await response.json()).sub_categories;
  }

  async getQuestions(subCategoryId: number): Promise<{
    category: Category,
    sub_category: SubCategory,
    questions: Question[]
  }> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/sub_categories/${subCategoryId}/questions`
    );
    if (!response.ok) {
      throw new Error('Could not get questions');
    }
    return response.json();
  }

  async answer(
    userData: UserData,
    devicesWithAnswers: DeviceWithAnswers[]
  ): Promise<{
    reference: string
  }> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/answers`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          user_data: {
            company: userData.companyName,
            vat_id: userData.vatId,
            sector: userData.sector,
            workers: userData.workers,
            region: userData.province,
            city: userData.municipality
          },
          devices: devicesWithAnswers
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not create answer');
    }
    return response.json();
  }

  async updateAnswer(
    reference:string,
    userData: UserData,
    devicesWithAnswers: DeviceWithAnswers[]
  ): Promise<{
    reference: string
  }> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/answers/${reference}`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          reference,
          user_data: {
            company: userData.companyName,
            vat_id: userData.vatId,
            sector: userData.sector,
            workers: userData.workers,
            region: userData.province,
            city: userData.municipality
          },
          devices: devicesWithAnswers
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not create answer');
    }
    return response.json();
  }

  async getResult(ref: string): Promise<Result[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/results/${ref}`
    );
    if (!response.ok) {
      throw new Error('Could not get results');
    }
    return (await response.json()).results;
  }

  async getAnswer(ref: string): Promise<ReultWithAnswers> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/answers/${ref}`
    );
    if (!response.ok) {
      throw new Error('Could not get questions');
    }
    return response.json();
  }
}