import { Button, Container, Grid, makeStyles, Paper, TextField, Typography, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useContext } from '../context';
import { FormField } from '../services/types/formField.type';

const useStlyes = makeStyles((theme) => ({
  option: {
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}))

const sectors = [
  'ALIMENTARIA',
  'AUTOMOCION',
  'CALZADO',
  'CERAMICA',
  'CONSTRUCCION',
  'CONSULTORIA_ASESORAMIENTO',
  'EFICIENCIA_ENERGETICA',
  'ELECTRICA',
  'ELECTRONICA',
  'ENERGIA_RENOVABLES',
  'ENERGIA_SOLAR',
  'FARMACEUTICA',
  'FERROVIARIA',
  'INFORMATICA',
  'JUGUETERA',
  'MADERA',
  'MECANICA',
  'METALURGICA',
  'PAPELERA',
  'PETROLIFERA',
  'PIROTECNICA',
  'PLASTICO',
  'QUIMICA',
  'RECICLADO',
  'SANITARIA',
  'SIDERUGICA',
  'TEXTIL',
  'SEGURIDAD',
  'TELECOMUNICACIONES',
  'TRANSPORTE',
  'SERVICIOS_ADMINISTRATIVOS',
  'SERVICIOS_COMERCIALES',
  'SERVICIOS_CULTURALES_OCIO',
  'SERVICIOS_DIRECCION_GESTION',
  'SERVICIOS_EDUCATIVOS',
  'SERVICIOS_FINANCIEROS',
  'SERVICIOS_INTERNACIONALIZACION',
  'SERVICIOS_LOGISTICA',
  'SERVICIOS_MANTENIMIENTO',
  'VEHICULOS',
  'OTROS'
];

const provinces = [
  {
    PROVINCE: 'CASTELLON',
    MUNICIPALITIES: [
      "Arañuel",
      "Argelita",
      "Ayódar",
      "Castillo de Villamalefa",
      "Cirat",
      "Cortes de Arenoso",
      "Espadilla",
      "Fanzara",
      "Fuente la Reina",
      "Fuentes de Ayódar",
      "Ludiente",
      "Montán",
      "Montanejos",
      "Puebla de Arenoso",
      "Toga",
      "Torralba del Pinar",
      "Torrechiva",
      "Vallat",
      "Villahermosa del Río",
      "Villamalur",
      "Villanueva de Viver",
      "Zucaina",
      "Algimia de Almonacid",
      "Almedíjar",
      "Altura",
      "Azuébar",
      "Barracas",
      "Bejís",
      "Benafer",
      "Castellnovo",
      "Caudiel",
      "Chóvar",
      "Gaibiel",
      "Geldo",
      "Higueras",
      "Jérica",
      "Matet",
      "Navajas",
      "Pavías",
      "Pina de Montalgrao",
      "Sacañet",
      "Segorbe",
      "Soneja",
      "Sot de Ferrer",
      "Teresa",
      "Torás",
      "Toro, El",
      "Vall de Almonacid",
      "Viver",
      "Alcalà de Xivert",
      "Benicarló",
      "Càlig",
      "Canet lo Roig",
      "Castell de Cabres",
      "Cervera del Maestre",
      "Xert",
      "Jana, la",
      "Peníscola/Peñíscola",
      "Pobla de Benifassà, la",
      "Rossell",
      "Salzadella, la",
      "Sant Jordi/San Jorge",
      "Sant Mateu",
      "San Rafael del Río",
      "Santa Magdalena de Pulpis",
      "Traiguera",
      "Vinaròs",
      "Castellfort",
      "Cinctorres",
      "Forcall",
      "Herbers",
      "Mata de Morella, la",
      "Morella",
      "Olocau del Rey",
      "Palanques",
      "Portell de Morella",
      "Todolella",
      "Vallibona",
      "Villores",
      "Zorita del Maestrazgo",
      "Almassora",
      "Benicàssim/Benicasim",
      "Benlloc",
      "Borriol",
      "Cabanes",
      "Castelló de la Plana",
      "Coves de Vinromà, les",
      "Orpesa/Oropesa del Mar",
      "Pobla Tornesa, la",
      "Serratella, la",
      "Sierra Engarcerán",
      "Torreblanca",
      "Torre d'en Doménec, la",
      "Vall d'Alba",
      "Vilafamés",
      "Vilanova d'Alcolea",
      "Sant Joan de Moró",
      "Aín",
      "Alcudia de Veo",
      "Alfondeguilla",
      "Almenara",
      "Artana",
      "Betxí",
      "Borriana/Burriana",
      "Chilches/Xilxes",
      "Eslida",
      "Llosa, la",
      "Moncofa",
      "Nules",
      "Onda",
      "Ribesalbes",
      "Suera/Sueras",
      "Tales",
      "Vall d'Uixó, la",
      "Vila-real",
      "Vilavella, la",
      "Alqueries, les/Alquerías del Niño Perdido",
      "Atzeneta del Maestrat",
      "Alcora, l'",
      "Benafigos",
      "Costur",
      "Xodos/Chodos",
      "Figueroles",
      "Llucena/Lucena del Cid",
      "Useres, les/Useras",
      "Vistabella del Maestrat",
      "Albocàsser",
      "Ares del Maestrat",
      "Benassal",
      "Catí",
      "Culla",
      "Tírig",
      "Torre d'En Besora, la",
      "Vilafranca/Villafranca del Cid",
      "Vilar de Canes"
    ]
  },
  {
    PROVINCE: 'ALICANTE',
    MUNICIPALITIES: [
      "Albatera",
      "Algorfa",
      "Almoradí",
      "Benejúzar",
      "Benferri",
      "Benijófar",
      "Bigastro",
      "Callosa de Segura",
      "Catral",
      "Cox",
      "Daya Nueva",
      "Daya Vieja",
      "Dolores",
      "Formentera del Segura",
      "Granja de Rocamora",
      "Guardamar del Segura",
      "Jacarilla",
      "Orihuela",
      "Rafal",
      "Redován",
      "Rojales",
      "San Fulgencio",
      "San Miguel de Salinas",
      "Torrevieja",
      "Pilar de la Horadada",
      "Montesinos, Los",
      "San Isidro",
      "Crevillent",
      "Elx/Elche",
      "Santa Pola",
      "Agres",
      "Alcocer de Planes",
      "Alcoleja",
      "Alfafara",
      "Almudaina",
      "Alqueria d'Asnar, l'",
      "Balones",
      "Benasau",
      "Beniarrés",
      "Benilloba",
      "Benillup",
      "Benimarfull",
      "Benimassot",
      "Cocentaina",
      "Quatretondeta",
      "Facheca",
      "Famorca",
      "Gaianes",
      "Gorga",
      "Orxa, l'/Lorcha",
      "Millena",
      "Muro de Alcoy",
      "Planes",
      "Tollos",
      "Algueña",
      "Aspe",
      "Elda",
      "Fondó de les Neus, el/Hondón de las Nieves",
      "Hondón de los Frailes",
      "Monforte del Cid",
      "Monòver/Monóvar",
      "Novelda",
      "Petrer",
      "Pinós, el/Pinoso",
      "Romana, la",
      "Atzúbia, l'",
      "Alcalalí",
      "Beniarbeig",
      "Benigembla",
      "Benidoleig",
      "Benimeli",
      "Benissa",
      "Poble Nou de Benitatxell, el/Benitachell",
      "Calp",
      "Castell de Castells",
      "Dénia",
      "Gata de Gorgos",
      "Xaló",
      "Xàbia/Jávea",
      "Llíber",
      "Murla",
      "Ondara",
      "Orba",
      "Parcent",
      "Pedreguer",
      "Pego",
      "Ràfol d'Almúnia, El",
      "Sagra",
      "Sanet y Negrals",
      "Senija",
      "Teulada",
      "Tormos",
      "Vall d'Alcalà, la",
      "Vall d'Ebo, la",
      "Vall de Gallinera",
      "Vall de Laguar, la",
      "Verger, el",
      "Poblets, els",
      "Alfàs del Pi, l'",
      "Altea",
      "Beniardá",
      "Benidorm",
      "Benifato",
      "Benimantell",
      "Bolulla",
      "Callosa d'en Sarrià",
      "Confrides",
      "Finestrat",
      "Castell de Guadalest, el",
      "Nucia, la",
      "Orxeta",
      "Polop",
      "Relleu",
      "Sella",
      "Tàrbena",
      "Vila Joiosa, la/Villajoyosa",
      "Agost",
      "Aigües",
      "Alacant/Alicante",
      "Busot",
      "Campello, el",
      "Xixona/Jijona",
      "Mutxamel",
      "Sant Joan d'Alacant",
      "Sant Vicent del Raspeig/San Vicente del Raspeig",
      "Torre de les Maçanes, la/Torremanzanas",
      "Alcoi/Alcoy",
      "Banyeres de Mariola",
      "Benifallim",
      "Castalla",
      "Ibi",
      "Onil",
      "Penàguila",
      "Tibi",
      "Beneixama",
      "Biar",
      "Camp de Mirra, el/Campo de Mirra",
      "Cañada",
      "Salinas",
      "Sax",
      "Villena",
    ]
  },
  {
    PROVINCE: 'VALENCIA',
    MUNICIPALITIES: [
      "Albalat dels Tarongers",
      "Alfara de la Baronia",
      "Algar de Palancia",
      "Algímia d'Alfara",
      "Benavites",
      "Benifairó de les Valls",
      "Canet d'En Berenguer",
      "Quart de les Valls",
      "Quartell",
      "Estivella",
      "Faura",
      "Gilet",
      "Petrés",
      "Sagunt/Sagunto",
      "Segart",
      "Torres Torres",
      "Benaguasil",
      "Benissanó",
      "Bétera",
      "Casinos",
      "Eliana, l'",
      "Llíria",
      "Loriguilla",
      "Marines",
      "Nàquera/Náquera",
      "Olocau",
      "Pobla de Vallbona, la",
      "Riba-roja de Túria",
      "Serra",
      "Vilamarxant",
      "Gátova",
      "San Antonio de Benagéber",
      "Ademuz",
      "Casas Altas",
      "Casas Bajas",
      "Castielfabib",
      "Puebla de San Miguel",
      "Torrebaja",
      "Vallanca",
      "Ayora",
      "Cofrentes",
      "Cortes de Pallás",
      "Jalance",
      "Jarafuel",
      "Teresa de Cofrentes",
      "Zarra",
      "Anna",
      "Bicorp",
      "Bolbaite",
      "Chella",
      "Enguera",
      "Millares",
      "Navarrés",
      "Quesa",
      "Alcúdia de Crespins, l'",
      "Barxeta",
      "Canals",
      "Cerdà",
      "Estubeny",
      "Font de la Figuera, la",
      "Genovés, el",
      "Granja de la Costera, la",
      "Xàtiva",
      "Llocnou d'En Fenollet",
      "Llanera de Ranes",
      "Llosa de Ranes, la",
      "Moixent/Mogente",
      "Montesa",
      "Novetlè/Novelé",
      "Rotglà i Corberà",
      "Torrella",
      "Vallada",
      "Vallés",
      "Alborache",
      "Buñol",
      "Cheste",
      "Chiva",
      "Dos Aguas",
      "Godelleta",
      "Macastre",
      "Siete Aguas",
      "Yátova",
      "Camporrobles",
      "Caudete de las Fuentes",
      "Chera",
      "Fuenterrobles",
      "Requena",
      "Sinarcas",
      "Utiel",
      "Venta del Moro",
      "Villargordo del Cabriel",
      "Alberic",
      "Alcàntera de Xúquer",
      "Alzira",
      "Alcúdia, l'",
      "Alfarp",
      "Algemesí",
      "Alginet",
      "Antella",
      "Beneixida",
      "Benifaió",
      "Benimodo",
      "Benimuslem",
      "Carcaixent",
      "Càrcer",
      "Carlet",
      "Catadau",
      "Cotes",
      "Ènova, l'",
      "Gavarda",
      "Guadassuar",
      "Llombai",
      "Manuel",
      "Massalavés",
      "Montserrat",
      "Montroi/Montroy",
      "Pobla Llarga, la",
      "Rafelguaraf",
      "Real",
      "Sant Joanet",
      "Sellent",
      "Senyera",
      "Sumacàrcer",
      "Tous",
      "Turís",
      "Castelló",
      "Albalat de la Ribera",
      "Almussafes",
      "Corbera",
      "Cullera",
      "Favara",
      "Fortaleny",
      "Llaurí",
      "Polinyà de Xúquer",
      "Riola",
      "Sollana",
      "Sueca",
      "Benicull de Xúquer",
      "Ador",
      "Alfauir",
      "Almiserà",
      "Almoines",
      "Alqueria de la Comtessa, l'",
      "Barx",
      "Bellreguard",
      "Beniarjó",
      "Benifairó de la Valldigna",
      "Beniflá",
      "Benirredrà",
      "Castellonet de la Conquesta",
      "Daimús",
      "Font d'En Carròs, la",
      "Gandia",
      "Guardamar de la Safor",
      "Xeraco",
      "Xeresa",
      "Llocnou de Sant Jeroni",
      "Miramar",
      "Oliva",
      "Palma de Gandía",
      "Palmera",
      "Piles",
      "Potries",
      "Rafelcofer",
      "Real de Gandia, el",
      "Ròtova",
      "Simat de la Valldigna",
      "Tavernes de la Valldigna",
      "Vilallonga/Villalonga",
      "Atzeneta d'Albaida",
      "Agullent",
      "Albaida",
      "Alfarrasí",
      "Aielo de Malferit",
      "Aielo de Rugat",
      "Bèlgida",
      "Bellús",
      "Beniatjar",
      "Benicolet",
      "Benigànim",
      "Benissoda",
      "Benissuera",
      "Bocairent",
      "Bufali",
      "Carrícola",
      "Castelló de Rugat",
      "Quatretonda",
      "Fontanars dels Alforins",
      "Guadasséquies",
      "Llutxent",
      "Montaverner",
      "Montitxelvo/Montichelvo",
      "Olleria, l'",
      "Ontinyent",
      "Otos",
      "Palomar, el",
      "Pinet",
      "Pobla del Duc, la",
      "Ráfol de Salem",
      "Rugat",
      "Salem",
      "Sempere",
      "Terrateig",
      "Albalat dels Sorells",
      "Alboraia/Alboraya",
      "Albuixech",
      "Alfara del Patriarca",
      "Almàssera",
      "Bonrepòs i Mirambell",
      "Burjassot",
      "Emperador",
      "Foios",
      "Godella",
      "Massalfassar",
      "Massamagrell",
      "Meliana",
      "Moncada",
      "Museros",
      "Pobla de Farnals, la",
      "Puig de Santa Maria, el",
      "Puçol",
      "Rafelbunyol",
      "Rocafort",
      "Tavernes Blanques",
      "Vinalesa",
      "Alaquàs",
      "Aldaia",
      "Quart de Poblet",
      "Xirivella",
      "Manises",
      "Mislata",
      "Paterna",
      "Picanya",
      "Torrent",
      "Albal",
      "Alcàsser",
      "Alfafar",
      "Benetússer",
      "Beniparrell",
      "Catarroja",
      "Llocnou de la Corona",
      "Massanassa",
      "Paiporta",
      "Picassent",
      "Sedaví",
      "Silla",
      "Alcublas",
      "Alpuente",
      "Andilla",
      "Aras de los Olmos",
      "Benagéber",
      "Bugarra",
      "Calles",
      "Chelva",
      "Chulilla",
      "Domeño",
      "Gestalgar",
      "Higueruelas",
      "Losa del Obispo",
      "Pedralba",
      "Sot de Chera",
      "Titaguas",
      "Tuéjar",
      "Villar del Arzobispo",
      "Yesa, La",
      "València"
    ]
  }
]

const workers = [
  '1_a_5',
  '6_a_10',
  '10_a_50',
  '+50'
]

export const Check = ()=>{
  const theme = useTheme();
  const { t } = useTranslation('check');
  const history = useHistory();
  const context = useContext();

  const classes = useStlyes();
  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      companyName: {
        value: '',
        errorState: false,
        helperText: ''
      },
      vatId: {
        value: '',
        errorState: false,
        helperText: ''
      },
      sector: {
        value: '',
        errorState: false,
        helperText: ''
      },
      workers: {
        value: '',
        errorState: false,
        helperText: ''
      },
      province: {
        value: '',
        errorState: false,
        helperText: ''
      },
      municipality: {
        value: '',
        errorState: false,
        helperText: ''
      }
    }
  );

  React.useEffect(()=>{

    if(context.data.userData){
      if(context.data.userData.companyName){
        updateForm({field: 'companyName', data: {value: context.data.userData.companyName}});
      }
      if(context.data.userData.vatId){
        updateForm({field: 'vatId', data: {value: context.data.userData.vatId}});
      }
      if(context.data.userData.sector){
        updateForm({field: 'sector', data: {value: context.data.userData.sector}});
      }
      if(context.data.userData.workers){
        updateForm({field: 'workers', data: {value: context.data.userData.workers}});
      }
      if(context.data.userData.province){
        updateForm({field: 'province', data: {value: context.data.userData.province}});
      }
      if(context.data.userData.municipality){
        updateForm({field: 'municipality', data: {value: context.data.userData.municipality}});
      }
    }
  },[]);

  const handleFormChange = (fieldName: string, value: string) => {
    updateForm({ field: fieldName, data: { value, errorState: false, helperText: '' } });
  }

  const getMunicipalities: () => string[] = () => {
    if (form.province.value) {
      const province = provinces.find(p => p.PROVINCE === form.province.value);
      if (province) {
        return province.MUNICIPALITIES;
      }
    }
    return [];
  }

  const validate = (): boolean => {
    let hasError = false;
    if (form.companyName.value.length === 0) {
      updateForm({ field: 'companyName', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    if (form.vatId.value.length === 0) {
      updateForm({ field: 'vatId', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    if (form.sector.value.length === 0) {
      updateForm({ field: 'sector', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    if (form.workers.value.length === 0) {
      updateForm({ field: 'workers', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    if (form.province.value.length === 0) {
      updateForm({ field: 'province', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    if (form.municipality.value.length === 0) {
      updateForm({ field: 'municipality', data: { errorState: true, helperText: 'IS_REQUIRED' } })
      hasError = true;
    }
    return !hasError;
  }

  const submit = () => {
    if (!validate()) {
      return;
    }
    context.setData({
      userData: {
        companyName: form.companyName.value,
        vatId: form.vatId.value,
        sector: form.sector.value,
        workers: form.workers.value,
        province: form.province.value,
        municipality: form.municipality.value
      }
    });
    history.push('/devices');
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('CHECK_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align='justify'>
            {t('CHECK_INTRO')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ paddingTop: theme.spacing(2) }}>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('NOMBRE_EMPRESA')}
                value={form.companyName.value}
                error={form.companyName.errorState}
                helperText={form.companyName.helperText ? t(form.companyName.helperText) : ''}
                onChange={(e) => {
                  handleFormChange('companyName', e.target.value);
                }}
                variant='outlined'
                fullWidth={true}
                inputProps={{
                  autoComplete: 'chrome-off',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('CIF')}
                value={form.vatId.value}
                error={form.vatId.errorState}
                helperText={form.vatId.helperText ? t(form.vatId.helperText) : ''}
                onChange={(e) => {
                  handleFormChange('vatId', e.target.value);
                }}
                variant='outlined'
                fullWidth={true}
                inputProps={{
                  autoComplete: 'chrome-off',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                onChange={(event, value) => {
                  handleFormChange('sector', value);
                }}
                value={form.sector.value}
                options={sectors}
                classes={{
                  option: classes.option,
                }}
                disableClearable
                autoHighlight
                getOptionLabel={(option) => t(option)}
                getOptionSelected={(option, value) => option === value}
                renderOption={(option) => t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('SECTOR')}
                    variant='outlined'
                    fullWidth={true}
                    error={form.sector.errorState}
                    helperText={form.sector.helperText ? t(form.sector.helperText) : ''}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                onChange={(event, value) => {
                  handleFormChange('workers', value);
                }}
                value={form.workers.value}
                options={workers}
                classes={{
                  option: classes.option,
                }}
                disableClearable
                autoHighlight
                getOptionLabel={(option) => t(option)}
                getOptionSelected={(option, value) => option === value}
                renderOption={(option) => t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('NUMERO_TRABAJADORES')}
                    variant='outlined'
                    fullWidth={true}
                    error={form.workers.errorState}
                    helperText={form.workers.helperText ? t(form.workers.helperText) : ''}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                onChange={(event, value) => {
                  handleFormChange('municipality','');
                  handleFormChange('province', value);
                }}
                value={form.province.value}
                options={provinces.map(p => p.PROVINCE)}
                classes={{
                  option: classes.option,
                }}
                disableClearable
                autoHighlight
                getOptionLabel={(option) => t(option)}
                getOptionSelected={(option, value) => option === value}
                renderOption={(option) => t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('PROVINCIA')}
                    variant='outlined'
                    fullWidth={true}
                    error={form.province.errorState}
                    helperText={form.province.helperText ? t(form.province.helperText) : ''}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                onChange={(event, value) => {
                  handleFormChange('municipality', value);
                }}
                value={form.municipality.value}
                options={getMunicipalities()}
                classes={{
                  option: classes.option,
                }}
                disableClearable
                disabled={form.province.value === ''}
                autoHighlight
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
                renderOption={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('MUNICIPIO')}
                    variant='outlined'
                    fullWidth={true}
                    error={form.municipality.errorState}
                    helperText={form.municipality.helperText ? t(form.municipality.helperText) : ''}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end'>
              <Button
                variant='contained'
                onClick={()=>{
                  submit();
                }}
                color='primary'
                size='large'>
                {t('CONTINUAR')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>

  )

}