import { ButtonBase, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import buttonLeftWhite from '../assets/img/button-left-corner-white.svg';
import buttonRightWhite from '../assets/img/button-right-corner-white.svg';
import { Link as RouterLink } from 'react-router-dom';

export const SicheckButton = ({ color, text, link }: { color: 'white' | 'primary', text: string, link: string }) => {

  const theme = useTheme();

  return (
    <ButtonBase
      component={RouterLink}
      to={link}
      style={{
        backgroundColor: color === 'white' ? '#FFF' : theme.palette.primary.main,
        borderTopRightRadius: 100,
        borderBottomLeftRadius: 100,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      }}>

      <Typography variant='h5' style={{
        color: color === 'white' ?
          theme.palette.primary.main :
          theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightBold
      }}>
        {text}
      </Typography>

    </ButtonBase>

  )

}