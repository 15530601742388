import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link} from 'react-router-dom';
import { HelpText } from '../components/helpText';
import { useApi } from '../context';
import { MissingDocument, MissingMaintenance, Result as ResultType } from '../services/types/result.type';
import utils from '../services/utils.service';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DescriptionIcon from '@material-ui/icons/Description';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import { Config } from '../config';

const config = new Config();

const useStlyes = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  }

}));

const MaintenanceModal = ({
  open,
  onClose,
  maintenances
}: {
  open: boolean,
  onClose: () => void,
  maintenances: MissingMaintenance[]
}) => {

  const { t, i18n } = useTranslation('check');

  const getFormattedDate = (date: string) => {
    return moment(date.substring(0, 10)).format('MMMM YYYY');
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>{t('DETALLES_MANTENIMIENTO')}</DialogTitle>
      <DialogContent>
        {maintenances.length === 0 &&
          <Typography>
            {t('MAINTENANCE_SUCCESS')}
          </Typography>
        }
        {maintenances.length > 0 &&
          <Typography>
            {t('MAINTENANCE_FAILED_INTRO') + ':'}
          </Typography>
        }
        <List>
          {maintenances.map(m => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <EventAvailableIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={utils.getLocalizedValue(i18n, m.name)}
                  secondary={`${t('MISSING_MAINTENANCE')} ${getFormattedDate(m.expected_date)}`}
                />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

const DocumentationModal = ({
  open,
  onClose,
  documents
}: {
  open: boolean,
  onClose: () => void,
  documents: MissingDocument[]
}) => {

  const theme = useTheme();
  const { t, i18n } = useTranslation('check');

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('DOCUMENT_DETAILS')}</DialogTitle>
      <DialogContent>
        {documents.length === 0 &&
          <Typography>
            {t('DOCUMENT_SUCCESS')}
          </Typography>
        }
        {documents.length > 0 &&
          <Typography>
            {t('DOCUMENT_MISSING') + ':'}
          </Typography>
        }
        <List>
          {documents.map(d => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={utils.getLocalizedValue(i18n, d.name)} />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export const Result = () => {
  let { resultReference } = useParams<{ resultReference: string }>();
  const theme = useTheme();
  const { t, i18n } = useTranslation('check');
  const [loading, setLoading] = React.useState(true);
  const [results, setResults] = React.useState<ResultType[]>([]);
  const [showMissingMaintenances, setShowMissingMaintenances] = React.useState(false);
  const [showMissingDocuments, setShowMissingDocuments] = React.useState(false);
  const [missingMaintenances, setMissingMaintenances] = React.useState<MissingMaintenance[]>([]);
  const [missingDocuments, setMissingDocuments] = React.useState<MissingDocument[]>([]);

  const classes = useStlyes();

  const api = useApi();

  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      setResults(await api.getResult(resultReference));
    }, (e) => {
      if (!e) {
        setLoading(false);
      }
    });

  }, []);

  const getTextColor = (percentage?: number) => {
    if (percentage === undefined || percentage === null) {
      return '#4E4E51';
    }
    if (percentage > 80) {
      return '#4AA74D';
    } else if (percentage > 40) {
      return '#FD8A00';
    } else {
      return '#C83621';
    }
  }

  const getPercentage = (percentage?: number) => {
    if (percentage === null || percentage === undefined) {
      return '-';
    }
    return `${Math.round(percentage)} %`;
  }

  const getTotalScore = () => {
    let runningAverage = 0;
    let count = 0;
    for (const result of results) {
      if (
        result.result.maintenance_percentage !== undefined && result.result.maintenance_percentage !== null &&
        result.result.documentation_percentage !== undefined && result.result.documentation_percentage !== null
      ) {
        count += 2;
        runningAverage += result.result.maintenance_percentage + result.result.documentation_percentage;
      } else if (
        result.result.maintenance_percentage !== undefined && result.result.maintenance_percentage !== null
      ) {
        count += 1;
        runningAverage += result.result.maintenance_percentage;
      } else if (
        result.result.documentation_percentage !== undefined && result.result.documentation_percentage !== null
      ) {
        count += 1;
        runningAverage += result.result.documentation_percentage
      }
    }
    return runningAverage / count;
  }

  return (
    <Container>
      <MaintenanceModal
        open={showMissingMaintenances}
        onClose={() => {
          setShowMissingMaintenances(false)
        }}
        maintenances={missingMaintenances} />
      <DocumentationModal
        open={showMissingDocuments}
        onClose={() => {
          setShowMissingDocuments(false)
        }}
        documents={missingDocuments} />
      <Grid container spacing={2}>
        {loading &&
          <Grid item container justifyContent='center' style={{ marginTop: theme.spacing(4) }}>
            <CircularProgress />
          </Grid>
        }
        {results.length > 0 &&
          <>
            <Grid item md={2}></Grid>
            <Grid item xs={12} md={8} style={{ marginTop: theme.spacing(4) }}>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant='h6' align='center'>
                      {t('CODE')}
                      <HelpText text={{ es: t('CODE_HELPTEXT'), ca: t('CODE_HELPTEXT') }} />
                    </Typography>
                    <Typography
                      variant='h3'
                      align='center'
                      style={{
                        marginTop: theme.spacing(2),
                      }}>
                      {resultReference}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h6' align='center'>
                      {t('EV_TOTAL')}
                    </Typography>
                    <Typography
                      variant='h3'
                      align='center'
                      style={{
                        marginTop: theme.spacing(2),
                        color: getTextColor(getTotalScore())
                      }}>
                      {`${Math.round(getTotalScore())} %`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' style={{ marginTop: theme.spacing(2) }} spacing={2}>
                  <Grid item>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <Button startIcon={<EventIcon/>} variant='contained' color='primary' {...bindTrigger(popupState)}>
                            {t('ADD_TO_CALENDAR')}
                          </Button>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => {
                              popupState.close();
                              window.open(`https://calendar.google.com/calendar/r?cid=${config.CDN_BASE_URL}/calendars/${resultReference}.ics`);
                            }}>{t('GOOGLE')}</MenuItem>
                            <MenuItem onClick={() => {
                              popupState.close();
                              window.open(`${config.CDN_BASE_URL}/calendars/${resultReference}.ics`);
                            }}>{t('APPLE')}</MenuItem>
                            <MenuItem onClick={() => {
                              popupState.close();
                              window.open(`${config.CDN_BASE_URL}/calendars/${resultReference}.ics`);
                            }}>{t('OUTLOOK')}</MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </Grid>
                  <Grid item>
                    <Button component={Link} to={`/result/${resultReference}/update`} startIcon={<EditIcon/>} variant='contained' color='primary'>
                      {t('UPDATE_ANSWERS')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={2}></Grid>
          </>

        }
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ paddingTop: theme.spacing(2) }}>
            {results.map(r => {
              return (
                <React.Fragment key={r.sub_category.id}>
                  <Grid item xs={12} style={{ paddingTop: theme.spacing(4) }}>
                    <Typography variant='subtitle1' component='p'>
                      {r.device}
                    </Typography>
                    <Typography variant='subtitle2' color='textSecondary' component='span'>
                      {utils.getLocalizedValue(i18n, r.sub_category.name)}
                    </Typography>
                    <HelpText text={r.sub_category.help_text} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={4} style={{}}>
                      <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                          <Typography variant='h6' align='center'>
                            {t('MAINTENANCE_SCORE')}
                          </Typography>
                          <Typography
                            variant='h3'
                            align='center'
                            style={{
                              marginTop: theme.spacing(2),
                              color: getTextColor(r.result.maintenance_percentage)
                            }}>
                            {getPercentage(r.result.maintenance_percentage)}
                          </Typography>
                          <Grid container item justifyContent='flex-end'>
                            <Button
                              disabled={r.result.maintenance_percentage === undefined || r.result.maintenance_percentage === null}
                              onClick={() => {
                                setMissingMaintenances(r.result.missing_maintenances);
                                setShowMissingMaintenances(true);
                              }} size='small' color='primary'>
                              {t('RESULTADOS_DEL_INFORME_DE_CONTROL')}
                            </Button>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                          <Typography variant='h6' align='center'>
                            {t('DOCUMENTS_SCORE')}
                          </Typography>
                          <Typography
                            variant='h3'
                            align='center'
                            style={{
                              marginTop: theme.spacing(2),
                              color: getTextColor(r.result.documentation_percentage)
                            }}
                          >
                            {getPercentage(r.result.documentation_percentage)}
                          </Typography>
                          <Grid container item justifyContent='flex-end'>
                            <Button
                              disabled={r.result.documentation_percentage === undefined || r.result.documentation_percentage === null}
                              onClick={() => {
                                setMissingDocuments(r.result.missing_documents);
                                setShowMissingDocuments(true);
                              }} size='small' color='primary'>
                              {t('RESULTADOS_DEL_INFORME_DE_CONTROL')}
                            </Button>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Container>

  )

}