import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';


export const Legal = () => {
  const { t } = useTranslation('legal');

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('TITLE')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P1')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P2')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST1')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P3')}
          </Typography>
          <Typography>
            {t('P4')}
          </Typography>
          <Typography>
            {t('P5')}
          </Typography>
          <Typography>
            {t('P6')}
          </Typography>
          <Typography>
            {t('P7')}
          </Typography>
          <Typography gutterBottom>
            {t('P8')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST2')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P9')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P10')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P11')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P12')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P13')}
            <ul>
              <li>
                {t('P14')}
              </li>
              <li>
                {t('P15')}
              </li>
              <li>
                {t('P16')}
              </li>
            </ul>
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST3')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P17')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P18')}
            <ul>
              <li>
                {t('P19')}
              </li>
              <li>
                {t('P20')}
              </li>
            </ul>
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST4')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P21')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P22')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P23')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P24')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST5')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P25')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST6')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P26')}
            <ul>
              <li>
                {t('P27')}
              </li>
              <li>
                {t('P28')}
              </li>
              <li>
                {t('P29')}
              </li>
            </ul>
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST7')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P30')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST8')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P31')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST9')}
          </Typography>
          <Typography gutterBottom align='justify'>
            {t('P32')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )

}