import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LanguageIcon from '@material-ui/icons/Translate';
import Button from '@material-ui/core/Button';
import {
  MenuItem,
  Menu,
  useTheme,
  Grid,
  useMediaQuery,
  Link
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container } from '@material-ui/core';
import logo from '../assets/img/sicheck-logo-black.svg'
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Footer } from './footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: 50,
      maxWidth: '100%'
    },
    container: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6)
    },
    toolBarContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }),
);


const LanguageButton = () => {
  const { t, i18n } = useTranslation('layout');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: 'ca' | 'es') => {
    i18n.changeLanguage(language);
    closeLanguageMenu();
  }

  const getCurrentLanguage = () => {
    if (i18n.language.includes('ca')) {
      return t('VALENCIANO');
    } else {
      return t('CASTELLANO');
    }
  }

  return (
    <>
      <Button aria-controls='language-menu' aria-haspopup='true' onClick={openLanguageMenu}>
        <LanguageIcon />
        {getCurrentLanguage()}
        <ExpandMoreIcon fontSize='small' />
      </Button>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageMenu}
      >
        <MenuItem onClick={() => setLanguage('es')}>{t('CASTELLANO')}</MenuItem>
        <MenuItem onClick={() => setLanguage('ca')}>{t('VALENCIANO')}</MenuItem>
      </Menu>
    </>
  );
};

export const AppLayout = ({ children }: { children: any }) => {
  const classes = useStyles();
  const { t } = useTranslation('layout');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <MetaTags>
        <title>{t('TITLE')}</title>
        <meta name='description' content={t('DESCRIPTION')} />
      </MetaTags>
      <AppBar position='static' color='transparent' variant='outlined'>
        <Container className={classes.toolBarContainer}>
          <Toolbar>
            <Grid container alignItems='center'>
              <Grid item xs={12} md={4} >
                <Grid container justifyContent={isXs ? 'center' : 'flex-start'}>
                  <Grid item>
                    <Link component={RouterLink} to='/'>
                      <img className={classes.logo} src={logo} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8} >
                <Grid container justifyContent={isXs ? 'center' : 'flex-end'}>
                  <Grid item>
                    <Button component={RouterLink} to='/find-result' color='inherit'>{t('REVISAR')}</Button>
                  </Grid>
                  {/* <Grid item>
                    <Button component={RouterLink} to='/check' color='inherit'>{t('NUEVO_CHECK')}</Button>
                  </Grid> */}
                  <Grid item>
                    <LanguageButton />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.container}>
        {children}
      </div>
      <Footer />
    </div>
  )
};

