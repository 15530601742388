import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  useTheme,
  Grid,
  useMediaQuery,
  Typography,
  Link
} from '@material-ui/core';
import { Paper, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AlertDialog } from '../components/alertDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: 50,
      maxWidth: '100%'
    },
    container: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6)
    },
    footerContainer: {
      backgroundColor: theme.palette.secondary.main,
    },
    footerWrapper: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10)
    },
    footerText: {
      color: theme.palette.primary.contrastText
    },
    toolBarContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }),
);

export const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation('layout');
  const theme = useTheme();
  const [alertOpen, setAlertOpen] = React.useState(true);
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper className={classes.footerContainer} variant='outlined' square={true}>
      {/* <AlertDialog title={'Esta página se encuentra en construcción'} message={'Esta página todavía está en construcción. Disculpe las molestias.'} open={alertOpen} onClose={() => { setAlertOpen(false) }} /> */}
      <Container className={classes.footerWrapper}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Grid container direction='column' alignContent={isXs ? 'center' : 'flex-start'}>
              <Grid item>
                <Typography variant='h5' className={classes.footerText} gutterBottom align={isXs ? 'center' : 'left'}>
                  {t('CONTACTO')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.footerText} align={isXs ? 'center' : 'left'}>
                  96 351 68 35
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.footerText} align={isXs ? 'center' : 'left'}>
                  colegio@iicv.net
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            {!isXs &&
              <Grid container spacing={2} style={{ height: '100%' }} alignItems='flex-end' justifyContent='flex-end'>
                <Grid item>
                  <Typography variant='body2'>
                    <Link to='/legal' className={classes.footerText} component={RouterLink}>{t('AVISO_LEGAL')}</Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>
                    <Link to='/privacy' className={classes.footerText} component={RouterLink}>{t('POLITICA_PRIVACIDAD')}</Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>
                    <Link to='/cookies' className={classes.footerText} component={RouterLink}>{t('POLITICA_COOKIES')}</Link>
                  </Typography>
                </Grid>
              </Grid>
            }
            {isXs &&
              <Grid container spacing={1} style={{ height: '100%' }} alignItems='center' justifyContent='center'>
                <Grid item container xs={12} justifyContent='center'>
                  <Typography align='center' variant='body2'>
                    <Link to='/legal' className={classes.footerText} style={{ textAlign: 'center' }} component={RouterLink}>{t('AVISO_LEGAL')}</Link>
                  </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent='center'>
                  <Typography align='center' variant='body2'>
                    <Link to='/privacy' className={classes.footerText} style={{ textAlign: 'center' }} component={RouterLink}>{t('POLITICA_PRIVACIDAD')}</Link>
                  </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent='center'>
                  <Typography align='center' variant='body2'>
                    <Link to='/cookies' className={classes.footerText} style={{ textAlign: 'center' }} component={RouterLink}>{t('POLITICA_COOKIES')}</Link>
                  </Typography>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}