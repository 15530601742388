import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { AnswerType, Question } from '../services/types/question.type';
import utils from '../services/utils.service';
import { HelpText } from '../components/helpText';


export const QuestionEl = (props: {
  question: Question
  data: {
    selectedOptionIds: number[],
    value: moment.Moment | null,
    error: boolean,
    errorMessage: string
  },
  toggleOption: (optionId: number) => void,
  onChange: (value: moment.Moment | null) => void
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation('check')
  const q = props.question;

  const getQuestionBody = () => {
    if (q.answer_type === AnswerType.DATE) {
      return (
        <DatePicker
          clearable
          okLabel={t('OK')}
          cancelLabel={t('CANCEL')}
          clearLabel={t('CLEAR')}
          openTo='year'
          label={t('SELECT_DATE')}
          format='DD/MM/YYYY'
          disableFuture
          inputVariant='outlined'
          error={props.data.error}
          helperText={props.data.error ? t(props.data.errorMessage) : ''}
          onChange={(value) => {
            props.onChange(value)
          }}
          value={props.data.value} />
      )
    } else if (q.answer_type === AnswerType.MULTIPLE) {
      return (
        <FormGroup style={{ paddingLeft: theme.spacing(2) }}>
          {q.options.map(o => {
            return (
              <FormControlLabel
                key={o.id}
                control={
                  <Checkbox
                    color='primary'
                    checked={props.data.selectedOptionIds.includes(o.id)}
                    onChange={() => {
                      props.toggleOption(o.id);
                    }} />
                }
                label={
                  <>
                    {utils.getLocalizedValue(i18n, o.value)}
                    <HelpText text={o.help_text} />
                  </>
                }
              />
            )
          })}
        </FormGroup>
      )
    } else {
      return (
        <FormControl component='fieldset' error={props.data.error} style={{ paddingLeft: theme.spacing(2) }}>
          <RadioGroup value={props.data.selectedOptionIds.length > 0 ? props.data.selectedOptionIds[0] : null} >
            {q.options.map(o => {
              return (
                <FormControlLabel
                  key={o.id}
                  value={o.id}
                  control={<Radio color='primary' />}
                  onChange={() => {
                    props.toggleOption(o.id);
                  }}
                  label={
                    <>
                      {utils.getLocalizedValue(i18n, o.value)}
                      <HelpText text={o.help_text} />
                    </>
                  } />
              )
            })}
          </RadioGroup>
          {props.data.error &&
            <FormHelperText>{t(props.data.errorMessage)}</FormHelperText>
          }
        </FormControl>
      )
    }
  }

  return (
    <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
      <Typography variant='subtitle2' style={{ marginBlock: theme.spacing(2) }}>
        {utils.getLocalizedValue(i18n, q.question)}
        <HelpText text={q.help_text} />
      </Typography>
      {getQuestionBody()}
    </Grid>
  );


}