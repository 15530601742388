import { LocalizedText } from './localizedText.type';

export enum AnswerType {
  MULTIPLE = 'MULTIPLE',
  BINARY = 'BINARY',
  DATE = 'DATE',
  MULTIPLE_MANDATORY_ONLY_ONE = 'MULTIPLE_MANDATORY_ONLY_ONE'
}

export enum QuestionType {
  DOCUMENTATION = 'DOCUMENTATION',
  MAINTENANCE = 'MAINTENANCE'
}

export type QuestionOption = {
  id: number;
  value: LocalizedText;
  help_text: LocalizedText;
  nested_questions: Question[];
}

export type Question = {
  id: number;
  sub_category_id: number;
  question: LocalizedText;
  help_text: LocalizedText;
  answer_type: AnswerType;
  type: QuestionType;
  options: QuestionOption[];
}