import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LocalizedText } from '../services/types/localizedText.type';
import utils from '../services/utils.service';
import HelpIcon from '@material-ui/icons/Help';
import ReactMarkdown from 'react-markdown';

export const MarkDownDialog = ({
  open,
  onClose,
  title,
  content
}: {
  open: boolean,
  onClose: () => void,
  title: string
  content: string
}) => {
  const { t } = useTranslation('layout');
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      aria-labelledby='alert-dialog'
    >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <ReactMarkdown components={{
          img: ({ node, ...props }) => <img style={{ maxWidth: '100%', display:'block', margin:'0 auto' }}{...props} />
        }}>
          {content}
        </ReactMarkdown>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export const HelpText = (props: { text: LocalizedText }) => {
  const { t, i18n } = useTranslation('layout');

  const [showDialog, setShowDialog] = React.useState(false);

  const translatedValue = utils.getLocalizedValue(i18n, props.text);
  if (!translatedValue) {
    return (<></>)
  }
  if (utils.isValidHttpUrl(translatedValue)) {
    return (
      <IconButton component='a' target='_blank' href={utils.getLocalizedValue(i18n, props.text)!} size='small'>
        <HelpIcon color='primary' fontSize='small' />
      </IconButton>
    )
  } else {
    return (
      <>
        <IconButton onClick={() => { setShowDialog(true) }} size='small'>
          <HelpIcon color='primary' fontSize='small' />
        </IconButton>
        <MarkDownDialog
          title={t('HELP_DIALOG_TITLE')}
          content={translatedValue}
          open={showDialog}
          onClose={() => setShowDialog(false)}
        />
      </>
    )
  }

}