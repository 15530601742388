import { i18n } from 'i18next';
import { LocalizedText } from './types/localizedText.type';

const utils = {
  runAsync: (runnable: () => Promise<void>, onDone?: (error?: any) => void) => {
    runnable().then(() => {
      if (onDone) {
        onDone();
      }
    }).catch(e => {
      if (e) {
        console.error(e);
      }
      if (onDone) {
        onDone(e);
      }
    });
  },

  getLocalizedValue: (i18n: i18n, text?: LocalizedText): string | null => {
    if (!text) return null;
    if (i18n.language === 'es') {
      return text.es;
    }
    return text.ca;
  },

  isValidHttpUrl: (content: string): boolean => {
    let url;
    try {
      url = new URL(content);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

}
export default utils
