import { hot } from 'react-hot-loader';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Router } from './router';
import { SicheckProvider } from './context';
import { DatePickerProvider } from './components/datePicketProvider';


let theme = createTheme({
  props: {
    // MuiContainer:{
    //   maxWidth: 'lg'
    // }
  },
  overrides: {
    MuiButton: {
      label: {
        textAlign: 'center'
      }
    }
  },
  typography: {
    fontFamily: [
      'Ubuntu',
      'sans-serif'
    ].join(','),
    fontSize: 16,
    subtitle1: {
      fontWeight: 600,
      fontSize: 20
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 16
    }
  },
  palette: {
    type: 'light',
    primary: {
      light: '#4a58b8',
      main: '#002f87',
      dark: '#000a59',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#000000',
      main: '#080913',
      dark: '#000000',
      contrastText: '#ffffff',
    },
  },
});

theme = responsiveFontSizes(theme);

const App = () => (
  <React.Fragment>
    <DatePickerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
       
        <SicheckProvider>
          <Router />
        </SicheckProvider>
      </ThemeProvider>
    </DatePickerProvider >
  </React.Fragment>
)

export default hot(module)(App);
