export class Config {

  readonly API_BASE_URL: string;
  readonly GOOGLE_ANALITYCS_ID?: string;
  readonly CDN_BASE_URL: string;

  constructor() {
    this.API_BASE_URL = process.env.API_BASE_URL ? process.env.API_BASE_URL : '';
    this.GOOGLE_ANALITYCS_ID = process.env.GOOGLE_ANALITYCS_ID;
    this.CDN_BASE_URL = process.env.CDN_BASE_URL ? process.env.CDN_BASE_URL : '';
  }
}