import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AlertDialog } from '../components/alertDialog';
import { useApi, useContext } from '../context';
import utils from '../services/utils.service';
import { SubCategory } from '../services/types/subCategory.type';
import { Category } from '../services/types/category.type';
import { HelpText } from '../components/helpText';
import { QuestionEl } from './questionEl';
import { AnswerType, Question, QuestionType } from '../services/types/question.type';
import { LoadingButton } from '../components/loadingButton';
import { Answer } from '../services/types/answer.type';
import { Device } from '../services/types/device.type';
import { DeviceWithAnswers } from '../services/types/deviceWithAnswers.type'

const useStlyes = makeStyles((theme) => ({
  option: {
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}))

type DecoratedDevice = {
  device: Device;
  questions: Question[];
  dependencies: { [key: number]: number[] }
}

type QuestionData = {
  selectedOptionIds: number[];
  value: moment.Moment | null;
  error: boolean;
  errorMessage: string;
}

export const Questions = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation('check');

  const history = useHistory();
  const classes = useStlyes();

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [devices, setDevices] = React.useState<DecoratedDevice[]>([]);
  const [questionData, updateQuestionData] = React.useReducer((
    state: { [key: string]: QuestionData },
    action: { questionId: string, data: Partial<QuestionData> }
  ): { [key: string]: QuestionData } => {
    const updatedState = { ...state };
    if (updatedState[action.questionId]) {
      updatedState[action.questionId] = {
        ...updatedState[action.questionId],
        ...action.data
      }
    } else {
      updatedState[action.questionId] = {
        selectedOptionIds: [],
        value: null,
        error: false,
        errorMessage: '',
        ...action.data
      }
    }
    return updatedState;
  }, {});

  const context = useContext();
  const api = useApi();

  const validate = () => {
    let isValid = true;
    for (const device of devices) {
      for (const question of device.questions) {
        if (isQuestionRelevant(device, question)) {
          if (question.answer_type === AnswerType.DATE) {
            if (questionData[device.device.name + '-' + question.id].value === null) {
              isValid = false;
              updateQuestionData({
                questionId: device.device.name + '-' + question.id,
                data: {
                  error: true,
                  errorMessage: 'REQUIRED'
                }
              });
            }
          } else if (
            question.answer_type === AnswerType.MULTIPLE_MANDATORY_ONLY_ONE ||
            question.answer_type === AnswerType.BINARY
          ) {
            if (questionData[device.device.name + '-' + question.id].selectedOptionIds.length !== 1) {
              isValid = false;
              updateQuestionData({
                questionId: device.device.name + '-' + question.id,
                data: {
                  error: true,
                  errorMessage: 'MUST_SELECT_EXACTLY_ONE'
                }
              });
            }
          }
        }
      }
    }
    return isValid;
  }

  const submit = () => {
    if (!validate()) {
      return;
    }
    utils.runAsync(async () => {
      setSubmitting(true);
      const devicesWithAnswers: DeviceWithAnswers[] = [];
      for (const device of devices) {
        const answers: Answer[] = [];
        for (const question of device.questions) {
          if (isQuestionRelevant(device, question)) {
            answers.push({
              question_id: question.id,
              answer_value: questionData[device.device.name + '-' + question.id]?.value ? questionData[device.device.name + '-' + question.id]!.value!.format('YYYY-MM-DD') : null,
              answer_option_ids: questionData[device.device.name + '-' + question.id].selectedOptionIds
            });
          }
        }
        devicesWithAnswers.push({
          name: device.device.name,
          sub_categories_ids: [device.device.subcategory.id],
          answers
        })
      }
      const result = await api.answer(
        context.data.userData!,
        devicesWithAnswers
      );
      history.push(`/result/${result.reference}`);
    }, (e) => {
      if (e) {
        setError(true);
      }
      setSubmitting(false);
    });
  }

  const getNestedQuestions = (question: Question, nested: Question[] = []) => {
    if (question.options) {
      for (const option of question.options) {
        if (option.nested_questions) {
          for (const nestedQuestion of option.nested_questions) {
            nested.push(nestedQuestion);
            getNestedQuestions(nestedQuestion, nested);
          }
        }
      }
      return nested;
    }
    return nested;
  }

  const getDependencies = (question: Question, dependencies: { [key: number]: number[] }) => {
    if (question.options) {
      for (const option of question.options) {
        if (option.nested_questions) {
          for (const nestedQuestion of option.nested_questions) {
            if (dependencies[nestedQuestion.id]) {
              dependencies[nestedQuestion.id].push(option.id);
            } else {
              dependencies[nestedQuestion.id] = [option.id];
            }
            getDependencies(nestedQuestion, dependencies);
          }
        }
      }
      return dependencies;
    }
    return dependencies;
  }

  const getSelectedOptions = (device: Device) => {
    const selectedOptionIds: number[] = [];
    const deviceQuestionKeys = Object.keys(questionData).filter(k => k.includes(device.name + '-'));
    for (const key of deviceQuestionKeys) {
      selectedOptionIds.push(...questionData[key].selectedOptionIds);
    }
    return selectedOptionIds;
  }

  const removeDuplicates = (questions: Question[]) => {
    const deduplicatedQuestions: { [key: number]: Question } = {};
    for (const question of questions) {
      deduplicatedQuestions[question.id] = question;
    }
    return Object.values(deduplicatedQuestions);
  }

  const isQuestionRelevant = (device: DecoratedDevice, question: Question) => {
    if (device.dependencies[question.id]) {
      let found = false;
      for (const dependency of device.dependencies[question.id]) {
        if (getSelectedOptions(device.device).includes(dependency)) {
          found = true;
          break;
        }
      }
      return found;
    }
    return true;
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      let parsedSubCategories: DecoratedDevice[] = [];
      for (const device of context.data.devices) {
        let questions = await api.getQuestions(device.subcategory.id);
        const nestedQuestions = [];
        let dependencies = {};
        for (const question of questions.questions) {
          nestedQuestions.push(...getNestedQuestions(question));
          dependencies = { ...dependencies, ...getDependencies(question, {}) };
        }
        const parsedSubCategory = {
          device,
          questions: removeDuplicates([...questions.questions, ...nestedQuestions]).sort((a, b) => a.id - b.id),
          dependencies
        };
        for (const question of parsedSubCategory.questions) {
          updateQuestionData({
            questionId: device.name + '-' + question.id,
            data: {}
          });
        }
        parsedSubCategories.push(parsedSubCategory);
      }
      setDevices(parsedSubCategories);
    }, (e) => {
      if (!e) {
        setLoading(false);
      }
    })
  }, []);


  return (
    <Container>
      <AlertDialog
        open={error}
        title={'Revisa los errores del formulario'}
        message={'Por favor, revisa los errores del formulario antes de continuar.'}
        onClose={() => setError(false)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('CHECK_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align='justify'>
            {t('QUESTIONS_INTRO')}
          </Typography>
        </Grid>
        {loading &&
          <Grid item container justifyContent='center' style={{ marginTop: theme.spacing(4) }}>
            <CircularProgress />
          </Grid>
        }
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {devices.map(s => {
              return (
                <React.Fragment key={s.device.name}>
                  <Grid item xs={12} style={{ paddingTop: theme.spacing(4) }}>
                    <Typography variant='subtitle1' component='p'>
                      {s.device.name}
                    </Typography>
                    <Typography variant='subtitle2' color='textSecondary' component='span'>
                      {utils.getLocalizedValue(i18n, s.device.subcategory.name)}
                    </Typography>
                    <HelpText text={s.device.subcategory.help_text} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container style={{
                      paddingLeft: theme.spacing(4),
                    }}>
                      {s.questions.map(q => {
                        if (isQuestionRelevant(s, q)) {
                          return (
                            <QuestionEl
                              key={q.id}
                              data={questionData[s.device.name + '-' + q.id]}
                              onChange={(value) => {
                                updateQuestionData({
                                  questionId: s.device.name + '-' + q.id,
                                  data: {
                                    value,
                                    error: false,
                                    errorMessage: ''
                                  }
                                })
                              }}
                              toggleOption={(optionId) => {
                                if (
                                  q.answer_type === AnswerType.MULTIPLE_MANDATORY_ONLY_ONE ||
                                  q.answer_type === AnswerType.BINARY
                                ) {
                                  updateQuestionData({
                                    questionId: s.device.name + '-' + q.id,
                                    data: {
                                      selectedOptionIds: [optionId],
                                      error: false,
                                      errorMessage: ''
                                    }
                                  })
                                } else {
                                  const currentlySelectedOptions = questionData[s.device.name + '-' + q.id].selectedOptionIds;
                                  let selectedOptionIds = currentlySelectedOptions;
                                  if (currentlySelectedOptions.includes(optionId)) {
                                    selectedOptionIds.splice(currentlySelectedOptions.indexOf(optionId), 1);
                                  } else {
                                    selectedOptionIds.push(optionId);
                                  }
                                  updateQuestionData({
                                    questionId: s.device.name + '-' + q.id,
                                    data: { selectedOptionIds }
                                  })
                                }
                              }}
                              question={q} />
                          );
                        }
                      })}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid container item xs={12} justifyContent='flex-end' style={{ paddingTop: theme.spacing(4) }}>
              <LoadingButton
                loading={submitting}
                disabled={submitting || loading}
                variant='contained'
                onClick={() => {
                  submit();
                }}
                color='primary'
                size='large'>
                {t('CONTINUAR')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>

  )

}