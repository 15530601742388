import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment/locale/es';
import 'moment/locale/ca';
import * as ca from './assets/locale/ca/*.json'
import * as es from './assets/locale/es/*.json'


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      ca, es
    },
    supportedLngs: ['ca', 'es'],
    fallbackLng: 'es',
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;