import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Privacy = () => {
  const { t } = useTranslation('privacy');

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('TITLE')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P1')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST1')}
          </Typography>
          <Typography align='justify' gutterBottom>
            <ul>
              <li>
                {t('P2')}
              </li>
              <li>
                {t('P3')}
              </li>
              <li>
                {t('P4')}
              </li>
              <li>
                {t('P5')}
              </li>
              <li>
                {t('P6')}
              </li>
            </ul>
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST2')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P7')}
            <ul>
              <li>
                {t('P8')}
              </li>
              <li>
                {t('P9')}
              </li>
              <li>
                {t('P10')}
              </li>
            </ul>
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P11')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P12')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST3')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P13')}
          </Typography>
          <Typography variant='h6' gutterBottom>
            {t('ST4')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P14')}
          </Typography>
          <Typography variant='h6' gutterBottom>
            {t('ST5')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P15')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST6')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P16')}
          </Typography>
          <Typography variant='h6' gutterBottom>
            {t('ST7')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P17')}
          </Typography>
          <Typography variant='h6' gutterBottom>
            {t('ST8')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P18')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST9')}
          </Typography>
          <Typography variant='h6' gutterBottom>
            {t('ST10')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P19')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST11')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P20')}
            <ul>
              <li>
                {t('P21')}
              </li>
            </ul>
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST12')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P22')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P23')}
            <ul>
              <li>
                {t('P24')}
              </li>
              <li>
                {t('P25')}
              </li>
              <li>
                {t('P26')}
              </li>
              <li>
                {t('P27')}
              </li>
              <li>
                {t('P28')}
              </li>
              <li>
                {t('P29')}
              </li>
            </ul>
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST13')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P30')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST14')}
            {t('')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P31')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )

}