import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Translate';
import Button from '@material-ui/core/Button';
import {
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { Footer } from './footer';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: 50,
      maxWidth: '100%'
    },
    container: {
      flexGrow: 1,
    },
    toolBarContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }),
);



export const LandingLayout = ({ children }: { children: any }) => {
  const classes = useStyles();
  const { t } = useTranslation('layout');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container className={classes.root}>
      <MetaTags>
        <title>{t('TITLE')}</title>
        <meta name='description' content={t('DESCRIPTION')} />
      </MetaTags>
      <div className={classes.container}>
        {children}
      </div>
      <Footer />
      <CookieConsent
          enableDeclineButton
          location='bottom'
          buttonText='Aceptar'
          declineButtonText='Rechazar'
          style={{
            background: theme.palette.secondary.main,
          }}
          buttonStyle={{
            background: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            borderRadius: 20,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          declineButtonStyle={{
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: 20,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          expires={150}
        >
          <Typography>
          Utilizamos cookies propias y de terceros para facilitar su navegación en la página web, conocer cómo interactúas con nosotros y recopilar datos estadísticos. Por favor, lee nuestra <Link component={RouterLink} to='/cookies' underline='always' style={{color: theme.palette.primary.contrastText}}>Política de Cookies</Link> para conocer con mayor detalle las cookies que usamos, su finalidad y como poder configurarlas o rechazarlas, si así lo considera.
          </Typography>
        </CookieConsent>
    </Container>
  )
};

