import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';


export const AlertDialog = ({
  open,
  onClose,
  title,
  message
}: {
  open: boolean,
  onClose: () => void,
  title: string
  message: string
}) => {
  const {t} = useTranslation('layout');
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog'
    >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}
