import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useApi, useContext } from '../context';
import { Category } from '../services/types/category.type';
import { SubCategory } from '../services/types/subCategory.type';
import DeviceImg from '../assets/img/devices.svg';
import { AddDeviceModal } from './addDeviceModal';
import DeleteIcon from '@material-ui/icons/Delete';
import utils from '../services/utils.service';
import { HelpText } from '../components/helpText';
import { Device } from '../services/types/device.type';

type DecoratedCategory = Category & {
  subCategories: SubCategory[];
}


export const Devices = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation('check');
  const history = useHistory();

  const [shouldShowAddDeviceModal, setShouldShowAddDeviceModal] = React.useState(false);

  const [devices, updateDevices] = React.useReducer(
    (
      state: Device[],
      action: { action: 'add' | 'remove', device: Device }
    ): Device[] => {
      const updatedDevices: Device[] = [...state];
      const device = action.device;
      if (action.action === 'add') {
        if (updatedDevices.find(d => d.name.toUpperCase() === device.name.toUpperCase())) {
          const index = updatedDevices.findIndex(x =>
            x.name.toUpperCase() === device.name.toUpperCase()
          );
          updatedDevices.splice(index, 1);
        }
        updatedDevices.push(device);
      } else {
        const index = updatedDevices.findIndex(x =>
          x.name.toUpperCase() === device.name.toUpperCase()
        );
        updatedDevices.splice(index, 1);
      }
      return updatedDevices;
    },
    []
  );

  const context = useContext();

  const submit = () => {
    if (devices.length === 0) return;
    context.setData({ devices });
    history.push('/questions');
  }

  const addDevice = () => {
    setShouldShowAddDeviceModal(true);
  }

  return (
    <Container>
      <AddDeviceModal
        show={shouldShowAddDeviceModal}
        onClose={() => setShouldShowAddDeviceModal(false)}
        onDeviceAdded={(device) => {
          updateDevices({
            action: 'add',
            device: { name: device.name.toUpperCase(), subcategory: device.subcategory }
          })
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('CHECK_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align='justify'>
            {t('DEVICES_INTRO')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
          {devices.length === 0 &&
            <Grid container item justifyContent='center'>
              <img style={{ maxWidth: '100%', maxHeight: 350 }} src={DeviceImg} />
            </Grid>
          }
          {devices.length > 0 &&
            <>
              <Typography variant='h6'>
                {t('CHECK_DEVICES_TITLE')}
              </Typography>
              <List>
                {devices.map(d =>
                  <ListItem key={d.name.toUpperCase()}>
                    <ListItemText
                      primary={d.name.toUpperCase()}
                      secondary={
                        <>
                          <Typography component='span' variant='body2'>
                            {utils.getLocalizedValue(i18n, d.subcategory.name)}
                          </Typography>
                          <HelpText text={d.subcategory.help_text} />
                        </>
                      } />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          updateDevices({ action: 'remove', device: d })
                        }}
                        edge="end"
                        aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            </>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ paddingTop: theme.spacing(2) }}>
            <Grid container item xs={12} justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Button
                  variant='outlined'
                  onClick={() => {
                    addDevice();
                  }}
                  color='secondary'
                  size='large'>
                  {t('ADD_DEVICE')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  disabled={devices.length === 0}
                  onClick={() => {
                    submit();
                  }}
                  color='primary'
                  size='large'>
                  {t('CONTINUAR')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>

  )

}