import React, { Reducer, Dispatch } from 'react';
import { Api } from './services/api.service';
import { Config } from './config';
import { UserData } from './services/types/userData.type';
import { Device } from './services/types/device.type';

const config = new Config();



type Data = {
  userData: UserData | null;
  devices: Device[]
};

export type Context = {
  data: Data;
  setData: Dispatch<Partial<Data>>,
  reset: () => void
};

const SicheckContext = React.createContext<Context | undefined>(undefined);

export function useApi(): Api {
  const context = React.useContext(SicheckContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of SicheckProvider');
  }
  return new Api(config);
}

export function useContext(): Context {
  const context = React.useContext(SicheckContext);
  if (context === undefined) {
    throw new Error('Use Store must be used inside of SicheckProvider');
  }
  return context;
}

export const SicheckProvider = ({ children }: { children: any }) => {
  const reducer: Reducer<Data, Partial<Data>> = (state: Data, action: Partial<Data>) => {
    return {
      ...state,
      ...action
    };
  }

  const [data, setData] = React.useReducer(
    reducer,
    {
      userData: null,
      devices: []
    }
  );

  const reset = () => {
    setData({
      userData: null,
      devices: []
    });
  }

  return (
    <SicheckContext.Provider value={{ data, setData, reset }}>
      {children}
    </SicheckContext.Provider>
  )
}
