import { Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Cookies = () => {
  const { t } = useTranslation('cookies');

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('TITLE')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST1')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P1')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P2')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P3')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P4')}
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST2')}
          </Typography>
          <Typography variant='h6' gutterBottom>
            {t('ST3')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P5')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('T1')}
                  </TableCell>
                  <TableCell>
                    {t('T2')}
                  </TableCell>
                  <TableCell>
                    {t('T3')}
                  </TableCell>
                  <TableCell>
                    {t('T4')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('TC1')}
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    {t('TC2')}
                  </TableCell>
                  <TableCell>
                    {t('TC3')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            {t('ST4')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P6')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('T1')}
                  </TableCell>
                  <TableCell>
                    {t('T2')}
                  </TableCell>
                  <TableCell>
                    {t('T3')}
                  </TableCell>
                  <TableCell>
                    {t('T4')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('TC4')}
                  </TableCell>
                  <TableCell>
                    {t('TC5')}
                  </TableCell>
                  <TableCell>
                    {t('TC6')}
                  </TableCell>
                  <TableCell>
                    {t('TC7')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            {t('ST5')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P7')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P8')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('T1')}
                  </TableCell>
                  <TableCell>
                    {t('T2')}
                  </TableCell>
                  <TableCell>
                    {t('T3')}
                  </TableCell>
                  <TableCell>
                    {t('T4')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('TC8')}
                  </TableCell>
                  <TableCell>
                    {t('TC9')}
                  </TableCell>
                  <TableCell>
                    {t('TC10')}
                  </TableCell>
                  <TableCell>
                    {t('TC11')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('TC12')}
                  </TableCell>
                  <TableCell>
                    {t('TC13')}
                  </TableCell>
                  <TableCell>
                    {t('TC14')}
                  </TableCell>
                  <TableCell>
                    {t('TC15')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' gutterBottom>
            {t('ST6')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P9')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P10')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P11')}
            <ul>
              <li>
                {t('P12')}
              </li>
              <li>
                {t('P13')}
              </li>
              <li>
                {t('P14')}
              </li>
              <li>
                {t('P15')}
              </li>
            </ul>
          </Typography>
          <Typography variant='h5' gutterBottom>
            {t('ST7')}
          </Typography>
          <Typography align='justify' gutterBottom>
            {t('P16')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )

}