import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation,
  Redirect
} from 'react-router-dom';
;
import { AppLayout } from './layout/appLayout';
import ReactGA from 'react-ga';
import { Config } from './config';
import { Check } from './check/check';
import { Questions } from './check/questions';
import { Result } from './result/result';
import { FindResult } from './result/findResult';
import { Devices } from './check/devices';
import { Landing } from './static/landing';
import { LandingLayout } from './layout/landingLayout';
import { Cookies } from './static/cookies';
import { Privacy } from './static/privacy';
import { Legal } from './static/legal';
import { Update } from './result/update';

const config = new Config();
if (config.GOOGLE_ANALITYCS_ID) {
  ReactGA.initialize(config.GOOGLE_ANALITYCS_ID);
}

const AppRoute = ({ component: Component, ...rest }: { component: any, exact?: boolean, path: string[] | string }) => (
  <DomRoute {...rest} render={(props) => <AppLayout><Component {...props} /></AppLayout>} />
)

const LandingRoute = ({ component: Component, ...rest }: { component: any, exact?: boolean, path: string[] | string }) => (
  <DomRoute {...rest} render={(props) => <LandingLayout><Component {...props} /></LandingLayout>} />
)

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (config.GOOGLE_ANALITYCS_ID) {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);
  return null;
}

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AppRoute exact path='/check' component={Check} />
        <AppRoute exact path='/devices' component={Devices} />
        <AppRoute exact path='/questions' component={Questions} />
        <AppRoute exact path='/find-result' component={FindResult} />
        <AppRoute exact path='/result/:resultReference' component={Result} />
        <AppRoute exact path='/result/:resultReference/update' component={Update} />
        <AppRoute exact path='/cookies' component={Cookies} />
        <AppRoute exact path='/privacy' component={Privacy} />
        <AppRoute exact path='/legal' component={Legal} />
        <LandingRoute exact path='/' component={Landing} />
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
}
